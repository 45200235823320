import ApplePayService from "./ApplePayService";

function allAllLettersUpperCase(str) {
  return str.split('').filter((char) => char !== char.toUpperCase()).length > 0;
}

function isNumeric(str) {
  return Number.isNaN(parseFloat(str));
}

class ApplePayRequest {
  constructor(
    currency,
    price,
    country,
    cardBrands,
    shippingMethods,
    shippingType,
    requiredBillingContactFields,
    requiredShippingContactFields,
    contactFields,
    contactFieldsMappedTo,
    lineItems,
    totalLabel,
    tokenizationKey,
    domainName,
    canMakePaymentsPromise
  ) {
    this.currency = currency;
    this.price = price;
    this.country = country;
    this.cardBrands = cardBrands;
    this.shippingMethods = shippingMethods;
    this.shippingType = shippingType;
    this.requiredBillingContactFields = requiredBillingContactFields;
    this.requiredShippingContactFields = requiredShippingContactFields;
    this.contactFields = contactFields;
    this.contactFieldsMappedTo = contactFieldsMappedTo;
    this.lineItems = lineItems;
    this.totalLabel = totalLabel;
    this.domainName = domainName;
    this.tokenizationKey = tokenizationKey;

    this.canMakePayment = canMakePaymentsPromise;

    this.paymentResponse = null;
  }

  static getOptionErrors(options) {
    const errors = [];

    if (typeof options.currency !== 'string' ||
      options.currency.length !== 3 ||
      allAllLettersUpperCase(options.currency)
    ) {
      errors.push('Currency must be an uppercase, valid, 3 letter currency code.')
    }

    if (typeof options.price !== 'string' ||
      isNumeric(options.currency)
    ) {
      errors.push('Price must be a numeric string.')
    }

    if (typeof options.country !== 'string' ||
      options.country.length !== 2 ||
      allAllLettersUpperCase(options.country)
    ) {
      errors.push('Country must be an uppercase, valid, 2 letter country code.')
    }

    return errors;
  }

  static areOptionsValid(options) {
    return ApplePayRequest.getOptionErrors(options).length === 0;
  }

  static create(options) {
    if (ApplePayRequest.areOptionsValid(options)) {
      // eslint-disable-next-line no-console
      console.error('Could not create PaymentRequestAbstraction. Please verify the provided options are valid.');
      return null;
    }

    const canMakePaymentsPromise = ApplePayService.canMakePayments(options).catch(() => {});

    return new ApplePayRequest(
      options.currency,
      options.price,
      options.country,
      options.cardBrands,
      options.shippingMethods,
      options.shippingType,
      options.requiredBillingContactFields,
      options.requiredShippingContactFields,
      options.contactFields,
      options.contactFieldsMappedTo,
      options.lineItems,
      options.totalLabel,
      options.tokenizationKey,
      options.domainName,
      canMakePaymentsPromise
    )
  }

  async capturePayment() {
    this.payment = await ApplePayService.begin(this);
    return true;
  }
}

export default ApplePayRequest;
