import Eventable from "../Eventable";
import GooglePayHtml from './OuterGooglePay.html';
import OuterGooglePayDispatcher from "./OuterGooglePayDispatcher";
import GooglePayCSS from './OuterGooglePay.css';
import URLParser from "../URLParser";

let completeCallbacksToUnmount = [];
let updatedTokenCallbacksToUnmount = [];

class OuterGooglePayField extends Eventable {
    constructor(options) {
        super();
        this.country = options.country;
        this.price = options.price;
        this.currency = options.currency;
        this.billingAddressRequired = options.billingAddressRequired;
        this.billingAddressParameters = options.billingAddressParameters;
        this.shippingAddressRequired = options.shippingAddressRequired;
        this.shippingAddressParameters = options.shippingAddressParameters;
        this.buttonType = options.buttonType;
        this.emailRequired = options.emailRequired;
        this.merchantId = options.merchantId;
        this.merchantName = options.merchantName;
        this.cardBrands = options.cardBrands;
        this.environment =  options.environment;
        this.token =  options.token;
        this.tokenizationKey =  options.tokenizationKey;
        this.listener = options.listener;

        this.iframe = null;

    }

    // eslint-disable-next-line class-methods-use-this
    unmount(selector) {
        document.querySelectorAll(selector).forEach((container) => {
            while (container.firstChild) {
                container.removeChild(container.firstChild);
            }
        })

        this.unmountListeners();
    }

    getUrl() {
        const gwroot = URLParser.googlePayIFrameRootUrl;

        const urlParameters = new URLSearchParams();

        urlParameters.set('country', this.country);
        urlParameters.set('price', this.price);
        urlParameters.set('currency', this.currency);
        urlParameters.set('billingAddressRequired', this.billingAddressRequired);
        urlParameters.set('billingAddressParameters', JSON.stringify(this.billingAddressParameters));
        urlParameters.set('shippingAddressRequired', this.shippingAddressRequired);
        urlParameters.set('shippingAddressParameters', JSON.stringify(this.shippingAddressParameters));
        urlParameters.set('buttonType', this.buttonType);
        urlParameters.set('emailRequired', this.emailRequired);
        urlParameters.set('merchantId', this.merchantId);
        urlParameters.set('merchantName', this.merchantName);
        urlParameters.set('cardBrands', JSON.stringify(this.cardBrands));
        urlParameters.set('environment', this.environment);
        urlParameters.set('token', this.token);
        urlParameters.set('tokenizationKey', this.tokenizationKey);


        return `${gwroot}/token/google_pay_field.php?${urlParameters.toString()}`;
    }

    mount(selector) {
      document.querySelectorAll(selector).forEach((container) => {
          const parser = new window.DOMParser();
          const html = parser.parseFromString(GooglePayHtml, 'text/html');
          const iframe = html.querySelector('iframe');
          iframe.setAttribute('src', this.getUrl());

          const styles = document.createElement("style");
          styles.type = 'text/css';
          styles.innerText = GooglePayCSS;

          if (container instanceof window.Node) {
              container.appendChild(styles);
              container.appendChild(iframe);
              this.iframe = iframe;
          }
      })

      this.mountListeners();
    }

    unmountListeners() {
        completeCallbacksToUnmount.forEach(callback => {
            this.listener.removeListener('complete', callback);
        })
        completeCallbacksToUnmount = [];

        updatedTokenCallbacksToUnmount.forEach(callback => {
            this.listener.removeListener('updatedToken', callback);
        })
        updatedTokenCallbacksToUnmount = [];
    }

    mountListeners() {
        const onComplete = (data) => {
            this.invokeCallbacks('complete', data)
        }
        this.listener.on('complete', onComplete);
        completeCallbacksToUnmount.push(onComplete);

        const onUpdatedToken = (data) => {
            this.invokeCallbacks('updatedToken', data);
        }
        this.listener.on('updatedToken', onUpdatedToken);
        updatedTokenCallbacksToUnmount.push(onUpdatedToken);
    }

    updateToken() {
        return new Promise((resolve) => {
            const updateToken = () => {
                resolve({
                    result: 'success'
                });
                this.removeListener('updatedToken', updateToken);
            }

            this.on('updatedToken', updateToken);
            OuterGooglePayDispatcher.dispatchUpdateToken(this);
        });
    }
}

OuterGooglePayField.FIELD_TYPE = 'googlePay';

export default OuterGooglePayField;
