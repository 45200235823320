(function () {
  // https://developer.mozilla.org/en-US/docs/Web/API/NodeList/forEach#Polyfill
  if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function (callback, thisArg) {
      thisArg = thisArg || window;
      for (let i = 0; i < this.length; i++) {
        callback.call(thisArg, this[i], i, this);
      }
    };
  }

  // https://github.com/jonathantneal/closest/blob/master/element-closest.js
  (function (ElementProto) {
    if (typeof ElementProto.matches !== 'function') {
      ElementProto.matches = ElementProto.msMatchesSelector || ElementProto.mozMatchesSelector || ElementProto.webkitMatchesSelector || function matches(selector) {
        const element = this;
        const elements = (element.document || element.ownerDocument).querySelectorAll(selector);
        let index = 0;

        while (elements[index] && elements[index] !== element) {
          ++index;
        }

        return Boolean(elements[index]);
      };
    }

    if (typeof ElementProto.closest !== 'function') {
      ElementProto.closest = function closest(selector) {
        let element = this;

        while (element && element.nodeType === 1) {
          if (element.matches(selector)) {
            return element;
          }

          element = element.parentNode;
        }

        return null;
      };
    }
  }(window.Element.prototype));

  // from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
  (function (arr) {
    arr.forEach((item) => {
      if (item.hasOwnProperty('remove')) {
        return;
      }
      Object.defineProperty(item, 'remove', {
        configurable: true,
        enumerable: true,
        writable: true,
        value: function remove() {
          this.parentNode.removeChild(this);
        },
      });
    });
  }([Element.prototype, CharacterData.prototype, DocumentType.prototype]));
}());

// https://gist.github.com/ShirtlessKirk/6cdc2c32ddd97dc9c706
(function datasetModule(global, definition) { // non-exporting module magic dance
'use strict';

  const
    amd = 'amd';
  const exports = 'exports'; // keeps the method names for CommonJS / AMD from being compiled to single character variable

  if (typeof define === 'function' && define[amd]) {
    define(() => definition(global));
  } else if (typeof module === 'function' && module[exports]) {
    module[exports] = definition(global);
  } else {
    definition(global);
  }
}(window, (global) => {
  let
    attribute;
  let attributes;
  let counter;
  let dash;
  let dataRegEx;
  const { document } = global;
  let hasEventListener;
  let length;
  let match;
  let mutationSupport;
  const test = document.createElement('_');
  const DOMAttrModified = 'DOMAttrModified';

  function clearDataset(event) {
    delete event.target._datasetCache;
  }

  function toCamelCase(string) {
    return string.replace(dash, (m, letter) => letter.toUpperCase());
  }

  function getDataset() {
    const
      dataset = {};

    attributes = this.attributes;
    for (counter = 0, length = attributes.length; counter < length; counter += 1) {
      attribute = attributes[counter];
      match = attribute.name.match(dataRegEx);
      if (match) {
        dataset[toCamelCase(match[1])] = attribute.value;
      }
    }

    return dataset;
  }

  function mutation() {
    if (hasEventListener) {
      test.removeEventListener(DOMAttrModified, mutation, false);
    } else {
      test.detachEvent(`on${DOMAttrModified}`, mutation);
    }

    mutationSupport = true;
  }

  if (test.dataset !== undefined) {
    return;
  }

  dash = /\-([a-z])/ig;
  dataRegEx = /^data\-(.+)/;
  hasEventListener = !!document.addEventListener;
  mutationSupport = false;

  if (hasEventListener) {
    test.addEventListener(DOMAttrModified, mutation, false);
  } else {
    test.attachEvent(`on${DOMAttrModified}`, mutation);
  }

  // trigger event (if supported)
  test.setAttribute('foo', 'bar');

  Object.defineProperty(global.Element.prototype, 'dataset', {
    get: mutationSupport
      ? function get() {
        if (!this._datasetCache) {
          this._datasetCache = getDataset.call(this);
        }

        return this._datasetCache;
      } : getDataset,
  });

  if (mutationSupport && hasEventListener) { // < IE9 supports neither
    document.addEventListener(DOMAttrModified, clearDataset, false);
  }
}));
