import Eventable from "../Eventable";
import URLParser from "../URLParser";
import ApplePayHtml from "./ApplePay.html";
import ApplePayCSS from "./ApplePay.css";
import ApplePayRequest from "./ApplePayRequest";
import TokenRepository from "../TokenRepository";

class ApplePayField extends Eventable {
  constructor(options) {
    super();

    this.applePayRequest = ApplePayRequest.create({
      country: options.country,
      price: options.price,
      currency: options.currency,
      cardBrands: options.cardBrands,
      shippingMethods: options.shippingMethods,
      shippingType: options.shippingType,
      requiredBillingContactFields: options.requiredBillingContactFields,
      requiredShippingContactFields: options.requiredShippingContactFields,
      contactFields: options.contactFields,
      contactFieldsMappedTo: options.contactFieldsMappedTo,
      lineItems: options.lineItems,
      totalLabel: options.totalLabel,
      tokenizationKey: options.tokenizationKey,
      domainName: options.domainName,
    })

    this.style = options.style;
    this.type = options.type;
    this.boundHandleOnClick = this.handleOnClick.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  unmount(selector) {
    document.querySelectorAll(selector).forEach((container) => {
      while (container.firstChild) {
        container.removeChild(container.firstChild);
      }
    })
  }

  getUrl() {
    const gwroot = URLParser.applePayIFrameRootUrl;

    const urlParameters = new URLSearchParams();

    urlParameters.set('country', this.country);
    urlParameters.set('price', this.price);
    urlParameters.set('currency', this.currency);
    urlParameters.set('cardBrands', JSON.stringify(this.cardBrands));
    urlParameters.set('shippingMethods', JSON.stringify(this.shippingMethods));

    return `${gwroot}/token/apple_pay_field.php?${urlParameters.toString()}`;
  }

  mount(selector) {
    document.querySelectorAll(selector).forEach((container) => {
      const parser = new window.DOMParser();
      const div = parser.parseFromString(ApplePayHtml, 'text/html').querySelector('#CollectJSApplePayButton');
      div.addEventListener('click', (e) => {
        this.invokeCallbacks('click', e)
      });

      div.classList.add(`collectjs-internal-apple-pay-button-style--${this.style}`)
      div.classList.add(`collectjs-internal-apple-pay-button-type--${this.type}`)

      const styles = document.createElement("style");
      styles.type = 'text/css';
      styles.innerText = ApplePayCSS;

      if (container instanceof window.Node) {
        container.appendChild(styles);
        container.appendChild(div);
      }
    })

    this.on('click', this.boundHandleOnClick);
  }

  handleOnClick () {
    this.applePayRequest
      .capturePayment()
      .then((e) => {
        this.invokeCallbacks('complete', {
          initiatingEvent: e
        });
      })
      // eslint-disable-next-line no-console
      .catch(err => console.error(err))
  }

  async updateToken(token, tokenizationKey) {
    const applePayResponse = this.applePayRequest.payment;

    if (applePayResponse === null) {
      return {
        result: 'error',
        error: 'User has not finished interacting with the digital wallet.' +
          ' Tokenizing the a digital wallet must occur after user has finished with it. '
      }
    }

    const fields = [];

    const addField = (elementId, value) => {
      fields.push({
        elementId,
        value
      })
    }

    addField('applepay_payment_data', applePayResponse.token.paymentData);

    if (applePayResponse.billingContact instanceof Object) {
      if (applePayResponse.billingContact.addressLines instanceof Array) {
        addField('address1', applePayResponse.billingContact.addressLines[0]);
        addField('address2', applePayResponse.billingContact.addressLines[1]);
      }

      if (typeof applePayResponse.billingContact.administrativeArea === 'string') {
        addField('state', applePayResponse.billingContact.administrativeArea);
      }

      if (typeof applePayResponse.billingContact.countryCode === 'string') {
        addField('country', applePayResponse.billingContact.countryCode);
      }

      if (typeof applePayResponse.billingContact.familyName === 'string') {
        addField('last_name', applePayResponse.billingContact.familyName);
      }

      if (typeof applePayResponse.billingContact.givenName === 'string') {
        addField('first_name', applePayResponse.billingContact.givenName);
      }

      if (typeof applePayResponse.billingContact.locality === 'string') {
        addField('city', applePayResponse.billingContact.locality);
      }

      if (typeof applePayResponse.billingContact.postalCode === 'string') {
        addField('zip', applePayResponse.billingContact.postalCode);
      }
    }

    if (applePayResponse.shippingContact instanceof Object) {
      if (applePayResponse.shippingContact.addressLines instanceof Array) {
        addField('shipping_address_1', applePayResponse.shippingContact.addressLines[0]);
        addField('shipping_address_2', applePayResponse.shippingContact.addressLines[1]);
      }

      if (typeof applePayResponse.shippingContact.administrativeArea === 'string') {
        addField('shipping_state', applePayResponse.shippingContact.administrativeArea);
      }

      if (typeof applePayResponse.shippingContact.countryCode === 'string') {
        addField('shipping_country', applePayResponse.shippingContact.countryCode);
      }

      if (typeof applePayResponse.shippingContact.familyName === 'string') {
        addField('shipping_lastname', applePayResponse.shippingContact.familyName);
      }

      if (typeof applePayResponse.shippingContact.givenName === 'string') {
        addField('shipping_firstname', applePayResponse.shippingContact.givenName);
      }

      if (typeof applePayResponse.shippingContact.locality === 'string') {
        addField('shipping_city', applePayResponse.shippingContact.locality);
      }

      if (typeof applePayResponse.shippingContact.postalCode === 'string') {
        addField('shipping_zip', applePayResponse.shippingContact.postalCode);
      }
    }

    if (typeof applePayResponse.shippingContact.phoneNumber === 'string') {
      if (this.applePayRequest.contactFieldsMappedTo === 'billing') {
        addField('phone', applePayResponse.shippingContact.phoneNumber);
      }

      if (this.applePayRequest.contactFieldsMappedTo === 'shipping') {
        addField('shipping_phone', applePayResponse.shippingContact.phoneNumber);
      }
    }

    if (typeof applePayResponse.shippingContact.emailAddress === 'string') {
      if (this.applePayRequest.contactFieldsMappedTo === 'billing') {
        addField('email', applePayResponse.shippingContact.emailAddress);
      }

      if (this.applePayRequest.contactFieldsMappedTo === 'shipping') {
        addField('shipping_email', applePayResponse.shippingContact.emailAddress);
      }
    }

    try {
      await TokenRepository.updateMultipartToken(token, tokenizationKey, fields);
      return {
        result: 'success'
      };
    } catch (e) {
      return {
        result: 'error'
      }
    }
  }
}

ApplePayField.FIELD_TYPE = 'applePay';

export default ApplePayField;
