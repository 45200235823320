class TokenResponse {
  /**
   * @param type
   * @param token
   * @param initiatedBy
   * @param tokenData
   */
  constructor(type, token, initiatedBy, tokenData) {
    this.tokenType = type;
    this.token = token;
    this.initiatedBy = initiatedBy;
    const { card, check } = tokenData.data;
    this.card = card;
    this.check = check;
  }
}

export default TokenResponse;
