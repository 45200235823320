import TokenRepository from "../TokenRepository";

class Field {
  static async lookupAndFormatToken(token, tokenizationKey, cardBrand) {
    const tokenData = await TokenRepository.lookupToken(token, tokenizationKey);

    return {
      ...tokenData,
      data: {
        ...tokenData.data,
        card: {
          ...tokenData.data.card,
          type: cardBrand.toLowerCase()
        }
      }
    }
  }

  // HACK ALERT -
  // This designed to create an event for Collect.js's default handler.
  static async generateFakeEvent(parentSelector) {
    return new Promise((resolve) => {
      const div = document.createElement('div');
      div.addEventListener('click', (e) => {
        resolve(e);
      })

      document.querySelector(parentSelector).appendChild(div);
      div.click();
    });
  }
}

export default Field;
