import Eventable from '../Eventable';

class OuterGooglePayListener extends Eventable {
    mount() {
        window.addEventListener('message', (event) => {
            switch (event.data.action) {
                case "google-pay-field-completed":
                    this.invokeCallbacks('complete', event.data.action.response);
                    break;
                case "google-pay-field-token-update-completed":
                    this.invokeCallbacks('updatedToken', event.data.action.response);
                    break;
                default:
                    break;
            }
        })
    }
}

export default OuterGooglePayListener;
