const currentScript = document.currentScript || document.querySelector('script[src*="token/Collect.js"]');

function isDevelopment() {
    const url = new URL(window.location);

    return [
      'dev0-sch.corp.nmi.com',
      'dev1-sch.corp.nmi.com',
      'transdev.nmi.com'
    ].includes(url.hostname);
}

export default {
  /**
   * @returns {string}
   */
  get lightboxUrl() {
    const pieces = this.scriptUrl.split('/');
    pieces.pop();
    return `${pieces.join('/')}/lightbox.php`;
  },

  get inlineUrl() {
    const pieces = this.scriptUrl.split('/');
    pieces.pop();
    return `${pieces.join('/')}/inline.php`;
  },

  get stylesheetUrl() {
    const pieces = this.scriptUrl.split('/');
    pieces.pop();
    return `${pieces.join('/')}/styles.css`;
  },


  /**
   *
   * @returns {string}
   */
  get tokenUrl() {
    const path = this.scriptUrl.split('/');
    path.pop();
    path.pop();
    return `${path.join('/')}/token/api/create`;
  },

  /**
   *
   * @returns {string}
   */
  get lookupUrl() {
    let path = this.scriptUrl.split('/');
    path.pop();
    path.pop();
    path = path.join('/');
    return `${path}/token/api/lookup`;
  },

  /**
   * @returns {string}
   */
  get gwroot() {
    let path = this.scriptUrl.split('/');
    path.pop();
    path.pop();
    path = path.join('/');
    return path;
  },

  get saveMultipartTokenUrl() {
      return `${this.gwroot}/token/api/save_multipart_token`
  },

  /**
   * @returns {string}
   */
  get origin() {
    const pathArray = this.scriptUrl.split('/');
    if (pathArray.length < 3) {
      throw new Error('Invalid script src');
    }
    const protocol = pathArray[0];
    const host = pathArray[2];
    return `${protocol}//${host}`;
  },

  /**
   * @returns {string | null}
   */
  get scriptUrl() {
    return currentScript.getAttribute('src');
  },

  /**
   * @returns {HTMLScriptElement | SVGScriptElement | Element}
   */
  get currentScriptNode() {
    return currentScript;
  },

  /**
   * @returns {string | null}
   */
  get googlePayIFrameRootUrl() {
      return isDevelopment() ? this.gwroot : 'https://collectcheckout.com';
  },

  /**
   * @returns {string | null}
   */
  get applePayIFrameRootUrl() {
      return isDevelopment() ? this.gwroot : 'https://collectcheckout.com';
  }
};
