import UrlParser from './URLParser';
import SafariFocusFix from './SafariFocusFix';
import TokenResponse from './TokenResponse';
import TokenRepository from "./TokenRepository";

const handleInlineResize = Symbol('handleInlineResize');
const handleFocus = Symbol('handleFocus');
const handleBlur = Symbol('handleBlur');
const handleValidationError = Symbol('handleValidationError');
const handleValidationSuccess = Symbol('handleValidationSuccess');
const handleSaveEmptyField = Symbol('handleSaveEmptyField');
const handleSaveLightboxToken = Symbol('handleSaveLightboxToken');
const handleSaveInlineToken = Symbol('handleSaveInlineToken');

export default class MessageHandler {
  static addMessageHandlersToWindow(CollectJS) {
    MessageHandler.CollectJS = CollectJS;
    window.addEventListener('message', (event) => {
      // Ignore all events that don't come from the same origin as CollectJS
      if (event.origin !== UrlParser.origin) {
        return;
      }
      if (event.data.action === 'resizeLightbox') {
        document.getElementById('CollectJSIframe').style.height = `${event.data.height}px`;
      }
      if (event.data.action === 'resize') {
        MessageHandler[handleInlineResize](event);
      }
      if (event.data.action === 'focus') {
        MessageHandler[handleFocus](event);
      }
      if (event.data.action === 'blur') {
        MessageHandler[handleBlur](event);
      }
      if (event.data.action === 'validationError') {
        MessageHandler[handleValidationError](event);
      }
      if (event.data.action === 'validationSuccess') {
        MessageHandler[handleValidationSuccess](event);
      }
      if (event.data.action === 'SaveEmptyField') {
        MessageHandler[handleSaveEmptyField](event);
      }
      if (typeof event.data.token === 'string' && event.data.action === 'SaveTokenForm') {
        MessageHandler[handleSaveLightboxToken](event);
      }
      if (event.origin === UrlParser.origin && event.data.action === 'closeIframe') {
        MessageHandler.CollectJS.closePaymentRequest();
      }
      if (event.data.action === 'FinalizeMultipartToken' && event.data.response === 'success') {
        MessageHandler[handleSaveInlineToken](event);
      }
    }, false);
  }

  static [handleInlineResize](event) {
    document.getElementById(`CollectJSInline${event.data.fieldId}`).style.height = `${event.data.height}px`;
    MessageHandler.iframeResponses.push(`CollectJSInline${event.data.fieldId}`);
    if (MessageHandler.iframeResponses.length === Object.entries(MessageHandler.CollectJS.iframes).length
      && MessageHandler.CollectJS.config.fieldsAvailableCallback
    ) {
      MessageHandler.CollectJS.config.fieldsAvailableCallback();
    }
  }

  static [handleFocus](event) {
    const ev = document.createEvent('CustomEvent');
    ev.initCustomEvent('focus', true, true, false);
    document.getElementById(`CollectJSInline${event.data.elementId}`).dispatchEvent(ev);

    if (SafariFocusFix.isAppleDevice()) {
      SafariFocusFix.fireTouchStartEvent(event.data.elementId);
    }
  }

  static [handleBlur](event) {
    const ev = document.createEvent('CustomEvent');
    ev.initCustomEvent('blur', true, true, { empty: event.data.empty });
    document.getElementById(`CollectJSInline${event.data.elementId}`).dispatchEvent(ev);
  }

  static [handleValidationError](event) {
    document.getElementById(`CollectJSInline${event.data.fieldId}`).classList.add('CollectJSInvalid');
    document.getElementById(`CollectJSInline${event.data.fieldId}`).classList.remove('CollectJSValid');

    if (MessageHandler.CollectJS.config.validationCallback) {
      MessageHandler.CollectJS.config.validationCallback(event.data.fieldId, false, event.data.message);
    }
  }

  static [handleValidationSuccess](event) {
    document.getElementById(`CollectJSInline${event.data.elementId}`).classList.remove('CollectJSInvalid');
    document.getElementById(`CollectJSInline${event.data.elementId}`).classList.add('CollectJSValid');
    if (MessageHandler.CollectJS.config.validationCallback) {
      MessageHandler.CollectJS.config.validationCallback(event.data.elementId, true, 'Success');
    }
  }

  static [handleSaveEmptyField](event) {
    if (event.data.elementId === 'cvv' &&
        ['show', 'hide'].includes(MessageHandler.CollectJS.config.fields.cvv.display)) {
        return;
    }

    document.getElementById(`CollectJSInline${event.data.elementId}`).classList.add('CollectJSInvalid');
    document.getElementById(`CollectJSInline${event.data.elementId}`).classList.remove('CollectJSValid');
    // Does not trigger any callbacks to the outside page.
    if (MessageHandler.CollectJS.config.validationCallback) {
      MessageHandler.CollectJS.config.validationCallback(event.data.elementId, false, 'Field is empty');
    }
  }

  static [handleSaveLightboxToken](event) {
    MessageHandler.CollectJS.tokenPromise
      .then(() => {
        return TokenRepository.lookupToken(event.data.token, MessageHandler.CollectJS.config.tokenizationKey);
      })
      .then((tokenData) => {
        const gatewayResponse = new TokenResponse(
          'lightbox',
          event.data.token,
          MessageHandler.CollectJS.initiatedBy,
          tokenData
        );

        MessageHandler.CollectJS.closePaymentRequest();
        MessageHandler.CollectJS.config.callback(gatewayResponse);
        MessageHandler.CollectJS.retokenize();
      });
  }

  static [handleSaveInlineToken](event) {
    MessageHandler.CollectJS.tokenPromise.then(({token}) => {
      if (event.data.token === token) {
        MessageHandler.completedIframes.push(event.data.elementId);
        // Once all of the iframes return, call the callback
        let allFound = false;

        if (MessageHandler.completedIframes.includes('ccnumber')
          && MessageHandler.completedIframes.includes('ccexp')
          && (
            MessageHandler.completedIframes.includes('cvv')
            || MessageHandler.CollectJS.config.fields.cvv.display !== 'required'
          )
        ) {
          allFound = true;
        }

        if (MessageHandler.completedIframes.includes('checkname')
          && MessageHandler.completedIframes.includes('checkaccount')
          && MessageHandler.completedIframes.includes('checkaba')
        ) {
          allFound = true;
        }

        if (allFound) {
          if (MessageHandler.CollectJS.responseTimeout) {
            window.clearTimeout(MessageHandler.CollectJS.responseTimeout);
          }
          TokenRepository.lookupToken(event.data.token, MessageHandler.CollectJS.config.tokenizationKey)
            .then((tokenData) => {
              const gatewayResponse = new TokenResponse(
                'inline',
                token,
                MessageHandler.CollectJS.initiatedBy,
                tokenData
              );
              if (!MessageHandler.callbackFiredAfterValidationSuccess) {
                MessageHandler.callbackFiredAfterValidationSuccess = true;
                MessageHandler.CollectJS.config.callback(gatewayResponse);
                MessageHandler.CollectJS.retokenize();
                MessageHandler.CollectJS.inSubmission = false;
              }
            });
        }
      }
    });
  }
}

MessageHandler.iframeResponses = [];
MessageHandler.completedIframes = [];
MessageHandler.callbackFiredAfterValidationSuccess = false;
