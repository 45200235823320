import ApplePayField from "./ApplePayField";

class ApplePayFieldFactory {
  constructor(config) {
    this.config = config;
    this.listener = null;
  }

  getApplePayErrors(options) {
    const errors = [];

    const numberOfMatchingElements = document.querySelectorAll(this.config.fields.applePay.selector).length;
    if (numberOfMatchingElements === 0) {
      return [];
    }

    if (numberOfMatchingElements > 1) {
      errors.push(`You may only mount 1 Apple Pay button. ` +
        `'${this.config.fields.applePay.selector}' matches ${numberOfMatchingElements} elements.`);
    }

    if (typeof options.country !== 'string') {
      errors.push('country must be provided to Collect.js when using Apple Pay.');
    }

    if (typeof options.price !== 'string') {
      errors.push('price must provided to Collect.js as a string when using Apple Pay.');
    }

    if (typeof options.currency !== 'string') {
      errors.push('currency must be provided to Collect.js when using Apple Pay.');
    }

    if (!options.allowedApplePayDomains.includes(window.location.host)) {
      errors.push(`Failed to create an Apple Pay button. You must allow ${window.location.host}` +
       `to use Apple Pay. See the Apple Pay settings page in the control panel for more details.`);
    }

    return errors;
  }

  create(options) {
    const errors = this.getApplePayErrors(options);

    if (errors.length > 0) {
      errors.forEach((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
      return {};
    }

    return new ApplePayField({
      country : options.country,
      price: options.price,
      currency: options.currency,
      cardBrands: options.cardBrands,
      shippingMethods: options.shippingMethods,
      shippingType: options.shippingType,
      requiredBillingContactFields: options.requiredBillingContactFields,
      requiredShippingContactFields: options.requiredShippingContactFields,
      contactFields: options.contactFields,
      contactFieldsMappedTo: options.contactFieldsMappedTo,
      lineItems: options.lineItems,
      totalLabel: options.totalLabel,
      type: options.type,
      style: options.style,
      tokenizationKey: options.tokenizationKey,
      domainName: window.location.host
    });
  }
}

export default ApplePayFieldFactory;

